<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";
import PaymentCreate from "@/views/pages/accounts/counterparties/modals/PaymentCreate.vue";
import PaymentUpdate from "@/views/pages/accounts/counterparties/modals/PaymentUpdate.vue";
import PaymentDelete from "@/views/pages/accounts/counterparties/modals/PaymentDelete.vue";

export default defineComponent({
  name: "Contracts",
  emits: ['update'],
  data() {
    return {
      paymentHeaders: [
        {
          field: 'date',
          label: 'Date',
          align: 'center'
        },
        {
          field: 'amount',
          label: 'Amount',
          align: 'center'
        },
        {
          field: 'file',
          label: 'File',
          align: 'center',
          excel_data: (payment) => {
            return payment.file ? payment.file.toString().split('/')[payment.file.toString().split('/').length - 1] : ''
          },
          searchable: false
        },
        {
          field: 'comment',
          label: 'Comment',
          align: 'center'
        },
        {
          field: 'actions',
          label: 'Actions',
          align: 'center'
        },
      ],
      paymentInfo: {}
    }
  },
  components: {
    Table,
    PaymentCreate,
    PaymentUpdate,
    PaymentDelete,
  },
  methods: {
    paymentChange() {
      this.$refs.table.getData()
      this.$emit('update')
    },
    async downloadFile(path) {
      if (!path) return;
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
  }
})
</script>

<template>
  <PaymentCreate ref="paymentCreate" @created="paymentChange()"/>
  <PaymentUpdate ref="paymentUpdate" :paymentInfo="paymentInfo" @updated="paymentChange()"/>
  <PaymentDelete ref="paymentDelete" :paymentInfo="paymentInfo" @deleted="paymentChange()"/>

  <Table name="Payments List"
         ref="table" searchable selectable
         :headers="paymentHeaders"
         :url="`/contract/payment/${this.$route.params.contract_slug}/list/`">
    <template v-slot:top-right>
      <b-button @click="this.$refs.paymentCreate.openModal()"
                variant="success">Create Payment
      </b-button>
    </template>
    <template v-slot:amount="{row: payment}">
      <span v-if="payment.amount">
        <i class="bx bx-dollar"></i>
        {{ parseFloat(payment.amount).toLocaleString('en-UK') }}</span>
    </template>
    <template v-slot:file="{row: payment}">
      <i @click="downloadFile(payment.file)" class="mdi mdi-file-download text-secondary cursor-pointer fs-5"
         v-if="payment.file"></i>
    </template>
    <template v-slot:actions="{row: payment}">
      <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
        <b-button variant="light" class="p-0"
                  data-bs-toggle="modal" data-bs-target="#UpdatePaymentModal"
                  @click="this.$refs.paymentUpdate.openModal(payment)">
          <div class="px-1">
            <i class="ri-pencil-fill align-bottom px-1"></i>
          </div>
        </b-button>
        <b-button @click="this.$refs.paymentDelete.openModal(payment)"
                  variant="light">
          <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
        </b-button>
      </b-button-group>
    </template>
  </Table>
</template>

