<script>
import {defineComponent} from 'vue'
import axios from "axios"
import Swal from "sweetalert2";

export default defineComponent({
  name: "OrderList",
  data() {
    return {
      searchText: '',
      orders: [],
      loading_orders: true,

      order_child_types: {
        container_order: '/orders/container/view/',
        wagon_order: '/orders/wagon/view/',
        wagon_empty_order: '/orders/empty-wagon/view/'
      },

      total_pages: 0,
      per_page: 10,
      active_page: 1,
    }
  },
  computed: {
    filteredOrders() {
      try {
        return this.orders.filter(order => {
          return order.order_number.toLowerCase().includes(this.searchText.toLowerCase()) ||
              (order.lot_number || '').toLowerCase().includes(this.searchText.toLowerCase()) ||
              (order.invoice_id || '').toLowerCase().includes(this.searchText.toLowerCase()) ||
              order.paid_status.toLowerCase().includes(this.searchText.toLowerCase()) ||
              (order.total_cost || '').toLowerCase().includes(this.searchText.toLowerCase())
        })
      } catch {
        return this.orders
      }
    },
    paginatedOrders() {
      return this.filteredOrders.slice((this.active_page - 1) * this.per_page, this.active_page * this.per_page)
    }
  },
  methods: {
    async getOrdersList() {
      try {
        this.loading_orders = true
        let response = await axios.get(`/customer/order_list/${this.$route.params.slug}/${this.$route.params.contract_slug}/`)
        this.orders = response.data.results
        this.total_pages = Math.ceil(this.orders.length / this.per_page)
        this.loading_orders = false
      } catch {
        console.log('error')
      }
    },
    downloadFile(path) {
      let url = process.env.VUE_APP_ORDER_URL + path
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },

    async changeOrderInvoiceStatus(order) {
      try {
        await axios.post(`/invoice/invoice_status/${order.invoice_id}/`, {
          paid_status: order.paid_status === 'unpaid' ? 'paid' : 'unpaid'
        })
        order.paid_status = order.paid_status === 'unpaid' ? 'paid' : 'unpaid'
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Invoice status changed successfully',
          showConfirmButton: false,
          timer: 30020
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong',
          showConfirmButton: false,
          timer: 3000
        })
      }
    }
  },
  watch: {
    per_page() {
      this.total_pages = Math.ceil(this.filteredOrders.length / this.per_page)
    },
    searchText() {
      this.active_page = 1
      this.total_pages = Math.ceil(this.filteredOrders.length / this.per_page)
    }
  },
  created() {
    this.getOrdersList()
  }
})
</script>

<template>
  <b-row class="mt-4 mb-3 align-items-center">
    <b-col :lg="8">
      <input v-model="searchText"
             class="form-control" placeholder="Search for orders" type="text">
    </b-col>
  </b-row>
  <div class="table-responsive">
    <table class="table table-striped table-nowrap">
      <thead>
      <tr class="text-center">
        <th scope="col">#</th>
        <th scope="col">Order</th>
        <th scope="col">Lot number</th>
        <th scope="col">Invoice</th>
        <th scope="col">Status</th>
        <th scope="col">File</th>
        <th class="text-center" scope="col">Total</th>
      </tr>
      </thead>
      <tbody v-if="!loading_orders">
      <template v-if="paginatedOrders.length > 0">
        <tr class="position-relative text-center align-middle"
            v-for="(order, index) in paginatedOrders" :key="order"
        >
          <td class="fw-bold">
            {{ active_page * per_page - per_page + index + 1 }}
          </td>
          <td>
            <h5 class="my-0" v-if="order_child_types[order.order_type]">
              <router-link :to="order_child_types[order.order_type] + order.order_number + '/'">
                <span class="badge badge-soft-secondary">
                  {{ order.order_number }}
                </span>
              </router-link>
            </h5>
            <span v-else>{{ order.order_number }}</span>
          </td>
          <td>{{ order.lot_number || '' }}</td>
          <td>{{ order.invoice_id }}</td>
          <td>
            <div class="dropdown">
              <span
                  style="font-size: 11px"
                  class="badge cursor-pointer"
                  :class="{
                      'badge-outline-warning': order.paid_status === 'unpaid',
                      'badge-outline-secondary': order.paid_status === 'paid',
                  }"
                  data-bs-toggle="dropdown"
              >
                {{ order.paid_status }}</span>
              <div class="dropdown-menu dropdown-menu-end">
                <div class="dropdown-item cursor-pointer"
                     @click="changeOrderInvoiceStatus(order)"
                >
                  <h6 class="my-0 py-0 d-inline align-middle">
                    Change status to <span class="fw-semibold" :class="{
                      'text-warning': order.paid_status === 'paid',
                      'text-secondary': order.paid_status === 'unpaid',
                  }">
                    {{ order.paid_status === 'unpaid' ? 'Paid' : 'Unpaid' }}
                  </span>
                  </h6>
                  <i class="bx bx-check-double text-success fs-20 align-middle ms-1"></i>
                </div>
              </div>
            </div>
          </td>
          <td>
            <i @click="downloadFile(order.file)"
               class="mdi mdi-file text-primary c_icon c_icon_hoverable fs-15"></i>
          </td>
          <td>${{
              parseFloat(order.total_cost || 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </td>
        </tr>
        <tr>
          <th colspan="6"></th>
          <th class="text-center bg-light">
            $ {{
              filteredOrders.reduce((acc, order) => acc + parseFloat(order.total_cost || 0), 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </th>
        </tr>
      </template>
      <template v-else>
        <tr class="text-center">
          <td colspan="7" class="py-5">
            No data found
          </td>
        </tr>
      </template>
      </tbody>
      <tbody>
      <tr v-if="loading_orders">
        <td colspan="6" class="text-center py-5">
          <b-spinner></b-spinner>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-if="paginatedOrders.length > 0" class="d-flex justify-content-between gap-3 align-items-center mb-3">
    <div class="btn-group">
      <button type="button" class="btn btn-light dropdown-toggle"
              data-bs-toggle="dropdown" aria-expanded="false">
        {{ per_page }}
      </button>
      <div class="dropdown-menu dropdownmenu-info">
        <a class="dropdown-item" v-for="page in [10, 25, 50, 100]" :key="`per_page_${page}`"
           :class="{
                              'text-info bg-soft-info fw-bold': per_page === page,
                          }"
           @click="per_page = page"
        >
          {{ page }}
        </a>
      </div>
    </div>
    <ul class="pagination pagination-rounded mb-0">
      <li class="page-item" :class="{'disabled': active_page === 1}">
        <a class="page-link"><i class="mdi mdi-chevron-left"></i></a>
      </li>
      <li
          v-for="page in total_pages" :key="`page_${page}`"
          class="page-item" :class="{
                        'active': page === active_page,
                        'cursor-pointer': page !== active_page,
                      }"
          @click="active_page = page"
      >
        <a class="page-link">{{ page }}</a>
      </li>
      <li class="page-item" :class="{'disabled': active_page === total_pages}">
        <a class="page-link"><i class="mdi mdi-chevron-right"></i></a>
      </li>
    </ul>
  </div>
</template>

