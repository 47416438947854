<template>
  <b-modal v-model="showModal" hide-footer title="Delete Payment" class="v-modal-custom" centered>
    <h5 class="fw-normal mb-2 lh-base">Are you sure you want to delete the payment with date
      <span class="fw-medium me-1">{{ payment.date }}</span> and following comment ?
    </h5>
    <span class="fw-normal me-1">
      <i class="mdi mdi-pencil text-muted me-1"></i>
      <i> {{ payment.comment }} </i>
    </span>?
    <form class="mt-3" @submit.prevent="deletePayment()">
      <input v-model="confirmation_text" class="form-control mb-3" placeholder="Yes">
      <div class="text-end">
        <b-button v-if="!is_deleting" variant="danger" :disabled="confirmation_text.trim().toUpperCase() !== 'YES'">
          <i class="bx bx bxs-trash-alt align-middle me-1"></i>
          Yes, Delete
        </b-button>
        <b-spinner v-else variant="danger" class="mb-0"></b-spinner>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['deleted'],
  name: "document_edit",
  data() {
    return {
      showModal: false,
      confirmation_text: '',
      payment: {},
      is_deleting: false
    }
  },
  methods: {
    openModal(payment) {
      this.confirmation_text = ''
      this.showModal = true
      this.payment = payment
    },
    async deletePayment() {
      if (this.confirmation_text.trim().toUpperCase() !== 'YES') return;
      this.showModal = false
      this.$emit('deleted')
      await Swal.fire({
        icon: 'success',
        title: 'Payment deleted successfully',
        showConfirmButton: false,
        timer: 2000
      })
      try {
        await axios.delete(`/contract/payment/${this.data.id}/delete/`)
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  computed: {
    data() {
      return this.payment || {}
    }
  },
}
</script>

<style scoped>

</style>