<script>
import {defineComponent} from 'vue'
import OrderList from "@/views/pages/accounts/customers/components/OrderList.vue";
import Payments from "@/views/pages/accounts/counterparties/components/Payments.vue"
import axios from "axios";


export default defineComponent({
  name: "ContractOrders",
  components: {
    OrderList,
    Payments
  },
  data() {
    return {
      contract_request: {
        status: 'loading',
        data: {}
      }
    }
  },
  computed: {
    contractName() {
      try {
        let contract = this.$route.params.contract_slug;
        contract = contract.replace(/^"|"$/g, ''); // Remove quotation marks using regex
        return contract;
      } catch {
        return this.$route.params.contract_slug || "Contract";
      }
    },
  },
  methods: {
    async getContractDetails() {
      this.contract_request.status = 'loading'
      try {
        let response = await axios.get(`/contract/${this.$route.params.contract_slug}/`)
        this.contract_request.data = response.data
        this.contract_request.status = 'success'
      } catch {
        this.contract_request.status = 'error'
      }
    },
  },
  mounted() {
    this.getContractDetails()
  }
})
</script>

<template>

  <b-card class="mb-3">
    <b-row class="gy-2">
      <b-col lg="3">
        <router-link :to="{name: 'customer_profile_main'}">
          <b-button variant="light" class="btn-border">
            <i class="mdi mdi-keyboard-backspace align-bottom me-1"></i>
            Back
          </b-button>
        </router-link>
      </b-col>
      <b-col lg="9">
        <div v-if="contract_request.status === 'loading'" class="text-end">
          <b-spinner></b-spinner>
        </div>
        <div v-else-if="contract_request.status === 'success'"
             class="d-flex justify-content-end align-items-center gap-4 text-start">
          <div>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <h5 :class="{
                 'text-danger': parseFloat(contract_request.data.balance || 0) < 0,
                 'text-success': parseFloat(contract_request.data.balance || 0) >= 0,
               }"
                    class="mb-0">$ {{
                    parseFloat(contract_request.data.balance || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}</h5>
                <p class="text-muted mb-0">Total Balance</p>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <h5 class="mb-0">$ {{
                    parseFloat(contract_request.data.total_payment || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}</h5>
                <p class="text-muted mb-0">Total Payments</p>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <h5 class="mb-0">$ {{
                    parseFloat(contract_request.data.total_invoice || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}</h5>
                <p class="text-muted mb-0">Total Invoices</p>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>

  <b-row class="align-content-start justify-content-start g-4">
    <b-col xl="5">
      <b-card no-body>

        <b-card-header class="d-flex justify-content-between align-items-center">
          <b-card-title class="mb-0">
            <div v-if="contract_request.status === 'loading'">
              <b-spinner></b-spinner>
            </div>

            <router-link :to="{
              name: 'customer_profile_main', params: {
                slug: this.$route.params.slug
              }
            }" v-else-if="contract_request.status === 'success'">
              <h3 class="mb-0">
                <span class="badge badge-gradient-dark">{{ contract_request.data.name }}</span>
              </h3>
            </router-link>

            <b-alert v-else-if="contract_request.status === 'error'" size="sm" show variant="danger" class="mb-0">
              Couldn't load contract details
            </b-alert>

          </b-card-title>
        </b-card-header>

        <b-card-body class="py-0">
          <OrderList/>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col xl="7">
      <Payments/>
    </b-col>
  </b-row>

</template>

<style scoped>

</style>