<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "PaymentUpdate",
  emits: ['updated'],
  props: {
    paymentInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contract_slug: this.$route.params.contract_slug,
      save_current_file: true,
      newFile: null,

      showModal: false,
      payment: {}
    }
  },
  methods: {
    openModal(paymentInfo) {
      this.payment = paymentInfo
      this.showModal = true
    },
    onFileChange(event) {
      if (event.target.files[0] === undefined) {
        this.newFile = ''
      } else {
        this.newFile = event.target.files[0]
      }
    },
    async updatePayment() {
      try {
        let data = new FormData()

        data.append('date', this.payment.date)
        data.append('amount', this.payment.amount)
        data.append('file', this.save_current_file ? null : this.newFile)
        data.append('comment', this.payment.comment)
        data.append('contract_slug', this.contract_slug)

        await axios.put(`/contract/payment/${this.payment.id}/update/`, data)
        this.$emit('updated')
        this.showModal = false
        this.date = ''
        this.amount = ''
        this.file = ''
        this.comment = ''
        await Swal.fire({
          icon: 'success',
          title: 'Payment details updated successfully',
          showConfirmButton: false,
          timer: 3000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Couldn\'t update payment details!',
        })
      }
    }
  },
})
</script>

<template>
  <b-modal v-model="showModal" hide-footer title="Update Payment Details" class="v-modal-custom" centered>
    <form @submit.prevent="updatePayment()">
      <div class="mb-3">
        <label class="form-label text-dark">
          Date
          <span class="text-danger">*</span>
        </label>
        <input v-model="payment.date" type="date" class="form-control" placeholder="Select date">
      </div>
      <div class="mb-3">
        <label class="form-label text-dark">
          Amount
          <span class="text-danger">*</span>
        </label>
        <input v-model="payment.amount" step="any" type="number" class="form-control" placeholder="Enter amount">
      </div>
      <div class="mb-3">
        <div class="form-check mb-3">
          <input v-model="save_current_file" class="form-check-input" type="checkbox" id="saveCurrentPaymentFile">
          <label class="form-check-label" for="saveCurrentPaymentFile">
            Save current file ?
          </label>
        </div>
        <template v-if="save_current_file === false">
          <label class="form-label text-dark">
            Upload New File
            <span class="text-danger">*</span>
          </label>
          <input @change="onFileChange" type="file" class="form-control" placeholder="Upload file">
        </template>
      </div>
      <div class="mb-4">
        <label class="form-label text-dark">
          Comment
          <span class="text-danger">*</span>
        </label>
        <textarea class="form-control" rows="5" v-model="payment.comment"/>
      </div>
      <div class="text-end">
        <b-button variant="success" type="submit">
          Save payment
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>