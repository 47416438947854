<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "PaymentCreate",
  emits: ['created'],
  data() {
    return {
      date: '',
      amount: '',
      file: '',
      comment: '',
      contract_slug: this.$route.params.contract_slug,
      showModal: false
    }
  },
  computed: {
    isFormValid() {
      return this.date !== '' && this.amount !== '' && this.file !== '' && this.comment !== ''
    }
  },
  methods: {
    openModal() {
      try {
        document.getElementById('paymentCreateFile').value = ''
      } catch {
        console.log("Can't find element with id paymentCreateFile")
      }
      this.showModal = true
    },
    onFileChange(event) {
      if (event.target.files[0] === undefined) {
        this.file = ''
      } else {
        this.file = event.target.files[0]
      }
    },
    async createPayment() {
      if (!this.isFormValid) return
      try {
        let data = new FormData()

        data.append('date', this.date)
        data.append('amount', this.amount)
        data.append('file', this.file)
        data.append('comment', this.comment)
        data.append('contract_slug', this.contract_slug)

        await axios.post('/contract/payment/create/', data)
        this.$emit('created')
        this.showModal = false
        this.date = ''
        this.amount = ''
        this.file = ''
        this.comment = ''
        await Swal.fire({
          icon: 'success',
          title: 'Payment created successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  }
})
</script>

<template>
  <b-modal v-model="showModal" hide-footer title="Create New Payment" class="v-modal-custom" centered>
    <form @submit.prevent="createPayment()">
      <div class="mb-3">
        <label class="form-label text-dark">
          Date
          <span class="text-danger">*</span>
        </label>
        <input v-model="date" type="date" class="form-control" placeholder="Select date">
      </div>
      <div class="mb-3">
        <label class="form-label text-dark">
          Amount
          <span class="text-danger">*</span>
        </label>
        <input v-model="amount" type="number" step="any" class="form-control" placeholder="Enter amount">
      </div>
      <div class="mb-3">
        <label class="form-label text-dark">
          File
          <span class="text-danger">*</span>
        </label>
        <input id="paymentCreateFile" @change="onFileChange" type="file" class="form-control" placeholder="Upload file">
      </div>
      <div class="mb-4">
        <label class="form-label text-dark">
          Comment
          <span class="text-danger">*</span>
        </label>
        <textarea class="form-control" placeholder="Write your notes here..." rows="5" v-model="comment"/>
      </div>
      <div class="text-end">
        <b-button :disabled="!isFormValid" variant="success" type="submit">
          Create payment
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>